import {Canvas} from "@react-three/fiber"
import {KeyboardControls, PointerLockControls, Sky} from "@react-three/drei"
import {Physics} from "@react-three/rapier"
import {Player} from "./minecraft/Player"


export default function App() {
    return (
        <>
            <KeyboardControls
                map={[
                    {name: "forward", keys: ["ArrowUp", "w", "W"]},
                    {name: "backward", keys: ["ArrowDown", "s", "S"]},
                    {name: "left", keys: ["ArrowLeft", "a", "A"]},
                    {name: "right", keys: ["ArrowRight", "d", "D"]},
                    {name: "jump", keys: ["Space"]},
                    {name: "sprint", keys: ["ShiftLeft", "ShiftRight"]},
                    {name: "tenth", keys: ["Digit0"]},
                    {name: "first", keys: ["Digit1"]},
                    {name: "second", keys: ["Digit2"]},
                    {name: "third", keys: ["Digit3"]},
                    {name: "fourth", keys: ["Digit4"]},
                    {name: "fifth", keys: ["Digit5"]},
                    {name: "sixth", keys: ["Digit6"]},
                    {name: "seventh", keys: ["Digit7"]},
                    {name: "eighth", keys: ["Digit8"]},
                    {name: "ninth", keys: ["Digit9"]},
                ]}>
                <Canvas shadows camera={{fov: 65}}>
                    <Sky sunPosition={[100, 20, 100]}/>
                    <ambientLight intensity={0.3}/>
                    <pointLight castShadow intensity={0.8} position={[100, 100, 100]}/>
                    <Physics gravity={[0, -30, 0]}>
                        <Player/>
                    </Physics>
                    <PointerLockControls/>
                </Canvas>
            </KeyboardControls>
        </>
    )
}

import * as THREE from "three"
import * as RAPIER from "@dimforge/rapier3d-compat"
import {useCallback, useEffect, useRef} from "react"
import {useFrame} from "@react-three/fiber"
import {useKeyboardControls} from "@react-three/drei"
import {CapsuleCollider, RigidBody, useRapier} from "@react-three/rapier"
import Axe from "./Axe"
import Toolbar, {useToolStore} from "./Toolbar";
import {Blocks, useBlockStore} from "./Block";
import {Ground} from "./Ground";
import Hotkeys from "react-hot-keys";

const SPEED = 5
const direction = new THREE.Vector3()
const frontVector = new THREE.Vector3()
const sideVector = new THREE.Vector3()
const rotation = new THREE.Vector3()


export function Player({lerp = THREE.MathUtils.lerp}) {
    const ref = useRef<RAPIER.RigidBody>(null);
    const axe = useRef<any>();
    const toolbar = useRef<any>();
    const rapier = useRapier();
    const [sub, get] = useKeyboardControls()
    const storeAddBlock = useBlockStore((state) => state.addBlock)
    const storeRemoveBlock = useBlockStore((state) => state.removeBlock)
    const blocks = useBlockStore((state) => state.blocks)
    const activeTool = useToolStore((state) => state.activeTool)
    const setActiveTool = useToolStore((state) => state.setActiveTool)
    const textures = useToolStore((state) => state.textures)

    useEffect(() => {
        sub(
            (state) => state.first,
            (pressed) => {
                if (pressed) {
                    setActiveTool(0)
                }
            }
        )
        sub(
            (state) => state.second,
            (pressed) => {
                if (pressed) {
                    setActiveTool(1)
                }
            }
        )
        sub(
            (state) => state.third,
            (pressed) => {
                if (pressed) {
                    setActiveTool(2)
                }
            }
        )
        sub(
            (state) => state.fourth,
            (pressed) => {
                if (pressed) {
                    setActiveTool(3)
                }
            }
        )
        sub(
            (state) => state.fifth,
            (pressed) => {
                if (pressed) {
                    setActiveTool(4)
                }
            }
        )
        sub(
            (state) => state.sixth,
            (pressed) => {
                if (pressed) {
                    setActiveTool(5)
                }
            }
        )
        sub(
            (state) => state.seventh,
            (pressed) => {
                if (pressed) {
                    setActiveTool(6)
                }
            }
        )
        sub(
            (state) => state.eighth,
            (pressed) => {
                if (pressed) {
                    setActiveTool(7)
                }
            }
        )
        sub(
            (state) => state.ninth,
            (pressed) => {
                if (pressed) {
                    setActiveTool(8)
                }
            }
        )
        sub(
            (state) => state.tenth,
            (pressed) => {
                if (pressed) {
                    setActiveTool(9)
                }
            }
        )
        // first, second, third, fourth, fifth, sixth, seventh, eighth, ninth, tenth
    }, [])

    const calculateDistanceFromPlayer = (x: number, y: number, z: number) => {
        // player position
        const {x: px, y: py, z: pz} = ref.current!.translation();
        return Math.sqrt((px - x) * (px - x) + (py - y) * (py - y) + (pz - z) * (pz - z))
    };

    const addBlock = useCallback((x: number, y: number, z: number) => {
        if (ref.current !== undefined && ref.current !== null) {
            const activeTexture = useToolStore.getState().activeTexture
            console.log("add block")
            console.log(x, y, z)
            console.log(activeTexture)
            // is there a block already?
            const isBlock = blocks.find((block) => block.position[0] === x && block.position[1] === y && block.position[2] === z)
            if (isBlock !== undefined) {
                console.log("block already there")
                return
            }

            const distance = calculateDistanceFromPlayer(x, y, z)
            console.log("distance", distance)
            if (distance > 5) {
                console.log("too far away", distance)
                return
            }
            storeAddBlock(x, y, z, activeTexture)
        }
    }, []);

    const removeBlock = useCallback((x: number, y: number, z: number) => {
        console.log("remove block")
        console.log(x, y, z)

        const distance = calculateDistanceFromPlayer(x, y, z)
        console.log("distance", distance)
        if (distance > 5) {
            console.log("too far away", distance)
            return
        }
        storeRemoveBlock(x, y, z)
    }, []);

    const saveProgress = useCallback(() => {
        const blocks = useBlockStore.getState().blocks
        let output = ''
        for (let ind in blocks) {
            let block = blocks[ind]
            output += '{position: [' + block.position + '], texture: Textures.' + block.texture.name + '},'
        }
        output = output.substring(0, output.length - 1)
        output = '[' + output + ']'
        const element = document.createElement("a");
        const file = new Blob([output], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "progress.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }, []);

    useFrame((state) => {
        const {forward, backward, left, right, jump, sprint} = get()
        if (ref.current !== undefined && ref.current !== null) {
            const velocity = ref.current.linvel()
            const {x, y, z} = ref.current.translation();
            state.camera.position.set(x, y, z)
            // update camera
            // calculate vector length x, y, z
            const length = Math.sqrt(velocity.x * velocity.x + velocity.y * velocity.y + velocity.z * velocity.z)


            // update axe
            axe.current.children[0].rotation.x = lerp(axe.current.children[0].rotation.x, Math.sin(+(length > 1) * state.clock.elapsedTime * 10) / 6, 0.1)
            axe.current.rotation.copy(state.camera.rotation)
            axe.current.position.copy(state.camera.position).add(state.camera.getWorldDirection(rotation).multiplyScalar(1))

            // update toolbar
            toolbar.current.rotation.copy(state.camera.rotation)
            toolbar.current.position.copy(state.camera.position).add(state.camera.getWorldDirection(rotation).multiplyScalar(1))

            // movement
            frontVector.set(0, 0, +backward - +forward)
            sideVector.set(+left - +right, 0, 0)
            direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED * (sprint ? 2 : 1)).applyEuler(state.camera.rotation)
            ref.current.setLinvel({x: direction.x, y: velocity.y, z: direction.z}, true)
            // jumping
            const world = rapier.world.raw()
            // @ts-ignore
            const ray = world.castRay(new RAPIER.Ray(ref.current.translation(), {x: 0, y: -1, z: 0}))
            const grounded = ray && ray.collider && Math.abs(ray.toi) <= 1.75
            if (jump && grounded) ref.current.setLinvel({x: 0, y: 7.5, z: 0}, true)
        }
    })
    return (
        <Hotkeys
            keyName={'ctrl+b'}
            onKeyDown={saveProgress}
        >
            <Ground addBlock={addBlock}/>
            <RigidBody ref={ref} colliders={false} mass={1} type="dynamic" position={[0, 10, 0]}
                       enabledRotations={[false, false, false]}>
                <CapsuleCollider args={[0.75, 0.5]}/>
            </RigidBody>
            <group ref={axe} onPointerMissed={(e) => (axe.current.children[0].rotation.x = -0.5)}>
                <Axe position={[0.3, -0.35, 0.5]}/>
            </group>
            <group ref={toolbar}>
                <Toolbar position={[0, -0.20, 0.6]} activeTool={activeTool} textures={textures}/>
            </group>
            {/*<Block texture={textures[0]} position={[0, 0.5, -10]} addBlock={addBlock}/>*/}
            <Blocks addBlock={addBlock} removeBlock={removeBlock} playerRef={ref}/>
        </Hotkeys>
    )
}

import dirtImg from './assets/textures/dirt.jpg';
import grassImg from './assets/textures/grass.jpg';
import glassImg from './assets/textures/glass.png';
import logImg from './assets/textures/log.jpg';
import woodImg from './assets/textures/wood.png';
import stoneImg from './assets/textures/stone.png';
import stoneBrickImg from './assets/textures/stone_bricks.png';
import azaleaLeavesImg from './assets/textures/azalea_leaves.png';
import floweringAzaleaLeavesImg from './assets/textures/flowering_azalea_leaves.png';

import { TextureLoader, NearestFilter, LinearMipMapLinearFilter  } from 'three';

export const dirt = new TextureLoader().load(dirtImg);
export const grass = new TextureLoader().load(grassImg);
export const glass = new TextureLoader().load(glassImg);
export const wood = new TextureLoader().load(woodImg);
export const log = new TextureLoader().load(logImg);
export const stone = new TextureLoader().load(stoneImg);
export const stoneBrick = new TextureLoader().load(stoneBrickImg);
export const azaleaLeaves = new TextureLoader().load(azaleaLeavesImg);
export const floweringAzaleaLeaves = new TextureLoader().load(floweringAzaleaLeavesImg);

dirt.magFilter = NearestFilter;
dirt.minFilter = LinearMipMapLinearFilter;
dirt.name = 'dirt';

grass.magFilter = NearestFilter;
grass.minFilter = LinearMipMapLinearFilter;
grass.name = 'grass';

glass.magFilter = NearestFilter;
glass.minFilter = LinearMipMapLinearFilter;
glass.name = 'glass';

wood.magFilter = NearestFilter;
wood.minFilter = LinearMipMapLinearFilter;
wood.name = 'wood';

log.magFilter = NearestFilter;
log.minFilter = LinearMipMapLinearFilter
log.name = 'log';

stone.magFilter = NearestFilter;
stone.minFilter = LinearMipMapLinearFilter;
stone.name = 'stone';

stoneBrick.magFilter = NearestFilter;
stoneBrick.minFilter = LinearMipMapLinearFilter;
stoneBrick.name = 'stoneBrick';

azaleaLeaves.magFilter = NearestFilter;
azaleaLeaves.minFilter = LinearMipMapLinearFilter;
azaleaLeaves.name = 'azaleaLeaves';

floweringAzaleaLeaves.magFilter = NearestFilter;
floweringAzaleaLeaves.minFilter = LinearMipMapLinearFilter;
floweringAzaleaLeaves.name = 'floweringAzaleaLeaves';

import axios from "axios";
import {CurrentTrack} from "../spotify/DataModels";
import {config} from "../config";


export default class K4Api {
    static async getCurrentTrack(): Promise<CurrentTrack> {
        return await axios.get<CurrentTrack>(`https://api.k4anubhav.com/spotify/${config.k4api_spotify_id}/current-track/`).then(
            response => response.data
        );
    }
}
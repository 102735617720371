import * as THREE from "three";
import React from "react";
import {useGLTF} from "@react-three/drei";
import {GLTF} from "three-stdlib";

type GLTFResult = GLTF & {
    nodes: {
        Mesh_1001_1: THREE.Mesh;
        Mesh_1001_2: THREE.Mesh;
    };
    materials: {
        material_2: THREE.MeshStandardMaterial;
        material_3: THREE.MeshStandardMaterial;
    };
};

export function Axe(props: JSX.IntrinsicElements["group"]) {
    const {nodes, materials} = useGLTF("/axe.glb") as GLTFResult;
    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Mesh_1001_1.geometry}
                material={materials.material_2}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Mesh_1001_2.geometry}
                material={materials.material_3}
            />
        </group>
    );
}

useGLTF.preload("/axe.glb");

export default Axe;
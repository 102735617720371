import React, {useEffect} from "react";
import {CurrentTrack, Image} from "./DataModels";
import {CardMedia, Skeleton} from "@mui/material";
import K4Api from "../services/K4Api";
import {config} from "../config";


interface SpotifyImageProps {
    sideLength: number
}

const SpotifyImage = (props: SpotifyImageProps) => {
    const [currentTrack, setCurrentTrack] = React.useState<CurrentTrack | null>(null);
    const [hover, setHover] = React.useState<boolean>(false)

    let getNearSizeImage = (images: Image[], width: number) => {
        let nearSizeImage = images[0];
        images.forEach(image => {
            if (image.width > width && image.width < nearSizeImage.width) {
                nearSizeImage = image;
            }
        });
        return nearSizeImage;
    }


    useEffect(() => {
        const interval = setInterval(() => {
            K4Api.getCurrentTrack().then((track) => {
                setCurrentTrack(track);
            }).catch((error) => {
                console.error(error);
                console.error(error.response);
            })
        }, config.k4api_spotify_check_interval);
        return () => clearInterval(interval);
    }, [])

    return (
        <>
            {
                currentTrack ? (
                    <CardMedia
                        component="img"
                        className={'pixelated-image'}
                        sx={{width: props.sideLength, height: props.sideLength, filter: hover ? 'brightness(0.5)' : 'brightness(1)'}}
                        image={getNearSizeImage(currentTrack.track.images, 50).url}
                        alt="Music cover"
                        onPointerMove={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                    />
                ) : (
                    <Skeleton variant="rectangular" width={props.sideLength} height={props.sideLength}/>
                )
            }
        </>
    )
}

export default SpotifyImage;
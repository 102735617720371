import * as THREE from "three"
import {LinearMipMapLinearFilter, NearestFilter, RepeatWrapping, TextureLoader} from "three"
import {CuboidCollider, RigidBody} from "@react-three/rapier"
import * as RAPIER from "@dimforge/rapier3d-compat";
import {ThreeEvent} from "@react-three/fiber/dist/declarations/src/core/events";
import {useCallback, useMemo, useRef} from "react";
import grassImg from './assets/textures/grass.jpg';

interface GroundProps {
    addBlock: (x: number, y: number, z: number) => void;
}

export function Ground(props: GroundProps) {
    const ref = useRef<RAPIER.RigidBody>(null);
    const texture = useMemo(() => {
        const t = new TextureLoader().load(grassImg)
        t.wrapS = RepeatWrapping
        t.wrapT = RepeatWrapping
        t.repeat.set(100, 100)
        return t
    }, [])

    texture.magFilter = NearestFilter;
    texture.minFilter = LinearMipMapLinearFilter;
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.repeat.set(100, 100);

    const onClick = useCallback((e: ThreeEvent<MouseEvent>) => {
        if (ref.current !== undefined && ref.current !== null) {
            const isLeftClick = e.button === 0
            e.stopPropagation();
            if (isLeftClick) {
                return;
            }
            const [x, , z] = Object.values(e.point).map((pos) => Math.ceil(pos));
            props.addBlock(x, 0.5, z);
        }
    }, []);

    texture.wrapS = texture.wrapT = THREE.RepeatWrapping
    return (
        <RigidBody {...props} type="fixed" colliders={false} ref={ref}>
            <mesh receiveShadow rotation-x={-Math.PI / 2} onClick={onClick}>
                <planeBufferGeometry attach="geometry" args={[100, 100]}/>
                <meshStandardMaterial map={texture} attach="material"/>
            </mesh>

            <CuboidCollider args={[100, 2, 100]} position={[0, -2, 0]}/>
        </RigidBody>
    )
}
import React from "react";
import * as THREE from "three";
import {Texture} from "three";
import create from "zustand";
import * as Textures from "./textures";

interface ToolProps {
    texture: Texture;
    position: [number, number, number];
    active: boolean;
}

export interface ToolStoreState {
    activeTool: number;
    activeTexture: Texture;
    textures: Texture[];
    setActiveTool: (index: number) => void;
}

export const useToolStore = create<ToolStoreState>((set) => ({
    activeTool: 0,
    activeTexture: Textures.dirt,
    textures: [
        Textures.dirt,
        Textures.grass,
        Textures.stone,
        Textures.wood,
        Textures.log,
        Textures.glass,
        Textures.stoneBrick,
        Textures.azaleaLeaves,
        Textures.floweringAzaleaLeaves,
        Textures.dirt,
    ],
    setActiveTool: (index: number) => set((state) => {
        console.log('setActiveTool', index);
        console.log({activeTexture: state.textures[index], activeTool: index})
        return {activeTexture: state.textures[index], activeTool: index};
    }),
}));

const Tool = (props: ToolProps) => {
    // const texture = texture;
    return (
        <group {...props}>
            {props.active &&
                <mesh geometry={new THREE.BoxGeometry(0.05, 0.05, 0)} rotation-x={-Math.PI}>
                    <pointsMaterial color={'white'}/>
                </mesh>
            }
            <mesh geometry={new THREE.BoxGeometry(0.045, 0.045, 0)} rotation-x={-Math.PI} position={[0, 0, 0.001]}>
                <meshBasicMaterial map={props.texture}/>
            </mesh>
        </group>
    );
}

interface ToolbarProps {
    position: [number, number, number];
    activeTool: number;
    textures: Texture[];
}


const Toolbar = (props: ToolbarProps) => {
    return (
        <group {...props}>
            <mesh geometry={new THREE.BoxGeometry(0.5, 0.05, 0)} rotation-x={-Math.PI}>
                <pointsMaterial color={'grey'}/>
            </mesh>
            <>
                {props.textures.map((texture, index) => <Tool key={index} texture={texture}
                                                              position={[0.05 * index - 0.225, 0, 0]}
                                                              active={index === props.activeTool}/>)}
            </>
        </group>
    );
}


export default Toolbar;